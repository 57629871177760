<template>
    <div class="homeView">
        <!--都没有数据时展示-->
        <div style="display:none;" class="allNotData"
             v-if="dataObj.swwdDataList.length==0&&dataObj.zxzcDataList.length==0&&dataObj.newDataList.length==0&&loadingObj.swwdLoading==false&&loadingObj.zxzcLoading==false&&loadingObj.newLoading==false">
            很抱歉,没有查找到您搜索的相关内容
        </div>
        <div>
            <!--最新政策数据-->
            <div class="swwdData">
                <!--顶部导航-->
                <div class="topNav">
                    <div class="topNavleft">
                        最新政策
                    </div>
                    <!--切换-->
                    <div class="sortTabs">
                        <div class="sortTabsList"
                             v-for="(item,index) in zxzcSort"
                             :key="index"
                             @click="sortTabsHandle(item.index,'zxzc')"
                             :class="item.index==keydata.zxzcIndex?'sortTabsListCheck':''">
                            {{ item.title }}
                        </div>
                    </div>
                    <!--更多按钮-->
                    <div class="moreData" v-if="dataObj.zxzcDataList.length!=0" @click="moreHandle(2)">更多</div>
                </div>
                <!--最新政策数据展示-->
                <div class="zxzcDataShow" v-loading="loadingObj.zxzcLoading">
                    <!--数据展示头部 -->
                    <div class="zxzcDataHead">
                        <!--头部名 标题-->
                        <div class="zxzcDataHeadTitle">标题</div>
                        <!--头部名 文号-->
                        <div class="zxzcDataHeadWh">文号</div>
                        <!--头部名 发布日期-->
                        <div class="zxzcDataHeadTime">发布日期</div>
                    </div>
                    <!--数据列表-->
                    <div class="zxzcDataListBox">
                        <div v-if="dataObj.zxzcDataList.length!=0" class="showDataList" v-for="(item,index) in dataObj.zxzcDataList" :key="index">
                            <!--标题名-->
                            <!-- <el-tooltip class="item" effect="dark" :content="item.title.replace(/<[^>]+>/g,'')" placement="top-start"> -->
                                <!--<div class="showDataListTitle" v-html="item.title.replace(/<[^>]+>/g,'')" @click="dataHandle(item.id,'zxzc',item.title)"></div>-->
                                <div class="showDataListTitle" v-html="item.title" @click="dataHandle(item.id,'zxzc',item.title,index)"></div>
                            <!-- </el-tooltip> -->
                            <!--发布文号-->
                            <div v-if="item.article_no&&item.article_no!=''">
                                <!-- <el-tooltip class="item" effect="dark" :content="item.article_no.replace(/<[^>]+>/g,'')" placement="top-start"> -->
                                    <!--<div class="showDataListWh" v-html="item.article_no.replace(/<[^>]+>/g,'')"></div>-->
                                    <div class="showDataListWh" v-html="item.article_no"></div>
                                <!-- </el-tooltip> -->
                            </div>
                            <div v-else>
                                <div class="showDataListWhc">-</div>
                            </div>
                            <!--发布日期-->
                            <div class="showDataListTime">{{ item.publish_time }}</div>
                        </div>
                        <!--无数据时的展示-->
                        <div  class="showDataListNot"  v-if="dataObj.zxzcDataList.length==0&&noDataType_zxzc==''&&loadingObj.zxzcLoading == false">
                            抱歉，此分类没有检索到相关数据，您可以点击其他分类查看
                        </div>
                        <div class="showDataListNot" v-if="dataObj.zxzcDataList.length==0&&noDataType_zxzc=='articleNo'&&loadingObj.zxzcLoading == false">
                            抱歉，此分类没有检索到相关数据，您可以点击其他分类查看
                        </div>
                    </div>
                    <!--分页-->
                    <div  class="fenyebox" v-loading="loadingObj.fenyeLoading" v-if="dataObj.zxzcDataList.length!=0">
                        <el-pagination
                            background
                            layout="prev, pager,jumper, next"
                            :page-size=10
                            :current-page.sync = "keydata.pageZxzc"
                            @size-change="handleSizeChangeZxzc"
                            @current-change="handleCurrentChangeZxzc"
                            :total="dataObj.zxzcDataTotal">
                        </el-pagination>
                    </div>
                </div>
            </div>
            <!--分割线-->
            <div class="division"></div>
            <!--税务问答数据-->
            <div class="swwdData">
                <!--顶部导航-->
                <div class="topNav">
                    <div class="topNavleft">
                        税务问答
                    </div>
                    <!--切换-->
                    <div class="sortTabs">
                        <div class="sortTabsList"
                             v-for="(item,index) in swwdSort"
                             :key="index"
                             @click="sortTabsHandle(item.index,'swwd')"
                             :class="item.index==keydata.swwdIndex?'sortTabsListCheck':''">
                            {{ item.title }}
                        </div>
                    </div>
                    <!--更多按钮-->
                    <div class="moreData" v-if="dataObj.swwdDataList.length!=0" @click="moreHandle(1)">更多</div>
                </div>
                <!--税务问答数据展示-->
                <div class="swwdDataShow" v-loading="loadingObj.swwdLoading">
                    <!--数据展示头部 -->
                    <div class="swwdDataHead">
                        <!--头部名 标题-->
                        <div class="swwdDataHeadTitle">标题</div>
                        <div class="swwdDataHeadDanWei hiddenClass">单位</div>
                        <!--头部名 发布日期-->
                        <div class="swwdDataHeadTime">发布日期</div>
                    </div>
                    <!--数据列表-->
                    <div class="swwdDataListBox">
                        <div v-if="dataObj.swwdDataList.length!=0" class="showDataList" v-for="(item,index) in dataObj.swwdDataList" :key="index">
                            <!--标题名-->
                            <!-- <el-tooltip class="item" effect="dark" :content="item.title.replace(/<[^>]+>/g,'')" placement="top-start"> -->
                                <!-- <div class="showDataListTitle" v-html="item.title.replace(/<[^>]+>/g,'')" @click="dataHandle(item.id,'swwd',item.title)"></div>-->
                                <div class="showDataListTitle" v-html="item.title" @click="dataHandle(item.id,'swwd',item.title,index)"></div>
                            <!-- </el-tooltip> -->
                            <!--单位-->
                            <div class="showDataListDanWei hiddenClass">{{ item.source }}</div>
                            <!--发布日期-->
                            <div class="showDataListTime">{{ item.publish_time }}</div>
                        </div>
                        <!--无数据时的展示-->
                        <!--<div class="showDataListNot" v-if="dataObj.swwdDataList.length==0&&noDataType_swwd==''">
                            未查询到当前数据
                        </div>-->
                        <div class="showDataListNot" v-if="dataObj.swwdDataList.length==0&&noDataType_swwd==''&&loadingObj.swwdLoading == false">
                            抱歉，此分类没有检索到相关数据，您可以点击其他分类查看
                        </div>
                        <div class="showDataListNot" v-if="dataObj.swwdDataList.length==0&&noDataType_swwd=='articleNo'&&loadingObj.swwdLoading == false">
                            抱歉，此分类没有检索到相关数据，您可以点击其他分类查看
                        </div>

                    </div>
                    <!--分页-->
                    <div  class="fenyebox" v-loading="loadingObj.fenyeLoading" v-if="dataObj.swwdDataList.length!=0">
                        <el-pagination
                            background
                            layout="prev, pager,jumper, next"
                            :page-size=10
                            :current-page.sync = "keydata.pageSwwd"
                            @size-change="handleSizeChangeSwwd"
                            @current-change="handleCurrentChangeSwwd"
                            :total="dataObj.swwdDataTotal">
                        </el-pagination>
                    </div>
                </div>
            </div>
            <!--分割线-->
            <div class="division"></div>
            <!--新媒体数据-->
            <div class="swwdData">
                <!--顶部导航-->
                <div class="topNav">
                    <div class="topNavleft">
                        解读
                    </div>
                    <!--切换-->
                    <div class="sortTabs">
                        <div class="sortTabsList"
                             v-for="(item,index) in newSort"
                             :key="index"
                             @click="sortTabsHandle(item.index,'new')"
                             :class="item.index==keydata.newIndex?'sortTabsListCheck':''">
                            {{ item.title }}
                        </div>
                    </div>
                    <!--更多按钮-->
                    <div class="moreData" v-if="dataObj.newDataList.length!=0" @click="moreHandle(3)">更多</div>
                </div>
                <!--新媒体数据展示-->
                <div class="newDataShow" v-loading="loadingObj.newLoading">
                    <!--数据展示头部 -->
                    <div class="newDataHead">
                        <!--头部名 标题-->
                        <div class="newDataHeadTitle">标题</div>
                        <!--头部名 文号-->
                        <div class="newDataHeadGzh">公众号</div>
                        <!--头部名 阅读量-->
                        <div class="newDataHeadGYdl">阅读量</div>
                        <!--头部名 发布日期-->
                        <div class="newDataHeadTime">发布日期</div>
                    </div>
                    <!--数据列表-->
                    <div class="newDataListBox">
                        <div v-if="dataObj.newDataList.length!=0" class="showDataList" v-for="(item,index) in dataObj.newDataList" :key="index">
                            <!--标题名-->
                            <!-- <el-tooltip class="item" effect="dark" :content="item.title.replace(/<[^>]+>/g,'')" placement="top-start"> -->
                                <!--<div class="showDataListTitle" v-html="item.title.replace(/<[^>]+>/g,'')" @click="dataHandleJD(item.url)"></div>-->
                                <div class="showDataListTitle" v-html="item.title" @click="dataHandleJD(item.url,index)"></div>
                            <!-- </el-tooltip> -->
                            <!--公众号-->
                            <div class="showDataListGzh">{{ item.account }}</div>
                            <!--阅读量-->
                            <div class="showDataListYdl">{{ item.readNum }}</div>
                            <!--发布日期-->
                            <div class="showDataListTime">
                                {{ (item.publishTime&&item.publishTime!=''&&item.publishTime.length>10)?item.publishTime.slice(0, 10):'-'}}
                            </div>
                        </div>
                        <!--无数据时的展示-->
                        <div  class="showDataListNot"  v-if="dataObj.newDataList.length==0&&noDataType_jd==''&&loadingObj.newLoading == false">
                            抱歉，此分类没有检索到相关数据，您可以点击其他分类查看
                        </div>
                        <div class="showDataListNot" v-if="dataObj.newDataList.length==0&&noDataType_jd=='articleNo'&&loadingObj.newLoading == false">
                            抱歉，此分类没有检索到相关数据，您可以点击其他分类查看
                        </div>
                    </div>
                    <!--分页-->
                    <div  class="fenyebox" v-loading="loadingObj.fenyeLoading" v-if="dataObj.newDataList.length!=0">
                        <el-pagination
                            background
                            layout="prev, pager,jumper, next"
                            :page-size=10
                            :current-page.sync = "keydata.pageNew"
                            @size-change="handleSizeChangeNew"
                            @current-change="handleCurrentChangeNew"
                            :total="dataObj.newDataTotal">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>

        <!--遮罩-->
        <div class="zhezhao" v-if="isZhezhaoOld">
            <div class="zhezhaoWhiteBox">
                <div class="zhezhaotext">验证成功!</div>
                <div class="zhezhaotext">即将为您跳转!</div>
                <div class="zhezhaotextBig">{{ readyNum }}</div>
            </div>
        </div>
        <!--遮罩-->
        <div class="newZhaoZhao" v-if="isZhezhao">
            <div class="zztextBox">
                验证成功,正在为您跳转...
            </div>
        </div>
    </div>
</template>

<script>
    String.prototype.replaceAll  = function(s1,s2){
        return this.replace(new RegExp(s1,"gm"),s2);
    }
    //接口
    import {doGet, doPost, doGetPhb, doPostPhb, doGetCode} from "@/api/request";

    export default {
        name: "home",
        computed: {
            searchDataFN() {
                return this.$store.state.searchData
            },
            //是否在验证验证码页面点击确认
            codeOKFN(){
                return this.$store.state.codeOK
            }
        },
        watch: {
            searchDataFN(curVal, oldVal) {
                this.keydata.pageSwwd = 1;
                this.keydata.pageZxzc = 1;
                this.keydata.pageNew = 1;
                this.keydata.searchKey = curVal.key;
                this.keydata.searchType = curVal.type;
                this.getSwwdData();
                if(this.keydata.zxzcIndex==4){
                    this.getZxzcDataJD()
                }else{
                    this.getZxzcData()
                }
                this.getNewData();
            },
            codeOKFN(cur){
                if(cur.status == 'ok'){
                    this.isZhezhao = true;
                    console.log(this.$store.state.detailInfo)
                    let isPhb = this.$store.state.detailInfo.isPhb
                    let that = this
                    this.readyJumpTimer = setInterval(function(){
                        that.readyNum -=1;
                        if(that.readyNum <= 0){
                            clearInterval(that.readyJumpTimer);
                            that.isZhezhao = false;
                            that.readyNum = 3
                            if(isPhb == true){
                                let idOrUl = that.$store.state.detailInfo.Oid
                                that.goDetailPHB(idOrUl)
                            }else{
                                let idOrUl = that.$store.state.detailInfo.Oid
                                let oType = that.$store.state.detailInfo.Otype
                                that.goDetail(idOrUl,oType)
                            }
                        }
                    },1000)


                }
            }
        },
        data(){
            return{
                //参数
                keydata:{
                    //税务问答选择
                    swwdIndex:1,
                    //最新政策选择
                    zxzcIndex:1,
                    //新媒体选择
                    newIndex:1,
                    //搜索内容
                    searchKey:'',
                    //搜索类型
                    searchType:'',
                    //页码
                    pageSwwd:1,
                    pageZxzc:1,
                    pageNew:1,
                    //条数
                    limit:10,

                },
                //准备跳转
                readyNum:3,
                readyJumpTimer:null,
                isZhezhao:false,
                isZhezhaoOld:false,

                searchSupType:['giao','title','articleNo','keyWord'],
                noDataType_swwd:'',
                noDataType_zxzc:'',
                noDataType_jd:'',
                //数据保存
                dataObj:{
                    //税务问答数据 - 答疑
                    swwdDataList:[],
                    swwdDataTotal:0,
                    zxzcDataList:[],
                    zxzcDataTotal:0,
                    newDataList:[],
                    newDataTotal:0,
                },
                //加载状态
                loadingObj:{
                    fenyeLoading:false,
                    //税务问答loading
                    swwdLoading:false,
                    //最新政策loading
                    zxzcLoading:false,
                    //新媒体
                    newLoading:false,
                },
                //税务问答分类
                swwdSort:[
                    {
                        title:'总局答疑',
                        index:1
                    },
                    {
                        title:'各省答疑',
                        index:2
                    },
                    {
                        title:'专家答疑',
                        index:3
                    }
                ],
                //最新政策
                zxzcSort:[
                    {
                        title:'国家税务法规',
                        index:1
                    },
                    {
                        title:'中央部门法规',
                        index:2
                    },
                    {
                        title:'地方财税政策',
                        index:3
                    },
                    {
                        title:'政策解读',
                        index:4
                    }
                ],
                //新媒体
                newSort:[
                    {
                        //title:'官方新媒体',
                        title:'官方解读',
                        index:1
                    },
                    {
                        //title:'企业新媒体',
                        title:'企业解读',
                        index:2
                    },
                    {
                        //title:'专家新媒体',
                        title:'专家解读',
                        index:3
                    }
                ]
            }
        },
        created() {
            //获取vuex保存信息
            this.keydata.searchKey = this.$store.state.searchData.key;
            this.keydata.searchType = this.$store.state.searchData.type;
            this.getSwwdData();
            this.getZxzcData()
            this.getNewData();
        },
        methods:{
            //分类切换
            sortTabsHandle(index,type){
                if(type=='swwd'){
                    this.keydata.pageSwwd = 1;
                    this.keydata.swwdIndex = index;
                    this.getSwwdData()
                }else if(type == 'zxzc'){
                    this.keydata.pageZxzc = 1;
                    this.keydata.zxzcIndex = index;
                    if(this.keydata.zxzcIndex==4){
                        this.getZxzcDataJD()
                    }else{
                        this.getZxzcData()
                    }

                }else if(type == 'new'){
                    this.keydata.pageNew = 1;
                    this.keydata.newIndex = index;
                    this.getNewData()
                }
            },

            //税务问答数据 答疑
            getSwwdData(){
                this.loadingObj.swwdLoading = true
                let url = '/retrieve/getAnswer';
                let data = {
                    size: 10,
                    page:this.keydata.pageSwwd,
                    operator: true,
                    isIncludeFiles: 0,
                    searchType: "time"
                };
                //判断类型
                data.orDayiTypes = this.keydata.swwdIndex
                // if(this.keydata.swwdIndex ==1){
                //     data.orDayiTypes = 1;
                // }else{
                //     data.orDayiTypes = 2;
                // }
                let isArticleNo = false
                //搜索
                if(this.keydata.searchType){
                    let typeArr = this.searchSupType
                    data[typeArr[this.keydata.searchType]] = this.keydata.searchKey
                    if(typeArr[this.keydata.searchType] == 'articleNo'&&this.keydata.searchKey!=''){
                        isArticleNo = true;
                    }
                }

                doGet(url,data).then(res => {
                    if(res.status == 200){
                        if(res.data.status == 0){
                            //判断是否是搜索文号
                            if(isArticleNo){
                                this.dataObj.swwdDataList = [];
                                this.dataObj.swwdDataTotal = 0;
                                this.noDataType_swwd = 'articleNo'
                            }else{
                                this.dataObj.swwdDataList = res.data.data.data;
                                this.dataObj.swwdDataTotal = res.data.data.total
                                this.noDataType_swwd = '';
                            }

                        }else{
                            console.log('数据返回错误')
                            console.log(res)
                            if(res.data.status == -1&&res.data.msg){
                                this.$message({
                                    type:'warning',
                                    message:res.data.msg
                                })
                            }
                            this.noDataType_swwd = '';
                        }
                    }else{
                        console.log('数据请求错误')
                        console.log(res)
                        this.noDataType_swwd = '';
                    }
                    this.loadingObj.swwdLoading = false
                }).catch(error => {
                    console.log('服务器请求错');
                    /*this.$message({
                        message:'服务器错误,请联系管理员!',
                        type:'error',
                        duration:'6000',
                    })*/
                    this.loadingObj.swwdLoading = false
                    this.noDataType_swwd = '';
                    console.log(error);
                })
            },

            //最新政策数据 - 法规
            getZxzcData(){
                this.loadingObj.zxzcLoading = true
                let url = '/retrieve/getLaws';
                let data = {
                    size: 10,
                    page:this.keydata.pageZxzc,
                    operator: true,
                    isIncludeFiles: 0,
                    searchType: "time",
                };
                //判断类型
                if(this.keydata.zxzcIndex ==1){
                    data.ruleName = 'taxLaw-zjfg';
                }else if(this.keydata.zxzcIndex ==2){
                    data.ruleName = 'zsg-qtzc';
                }else{
                    data.ruleName = 'zsg-dfzc';
                }
                let isArticleNo = false
                //搜索
                if(this.keydata.searchType){
                    let typeArr = this.searchSupType
                    data[typeArr[this.keydata.searchType]] = this.keydata.searchKey
                }

                doGet(url,data).then(res => {
                    if(res.status == 200){
                        if(res.data.status == 0){
                            if(isArticleNo){
                                this.dataObj.zxzcDataList = [];
                                this.dataObj.zxzcDataTotal = 0
                                this.noDataType_zxzc = 'articleNo'
                            }else{
                                this.dataObj.zxzcDataList = res.data.data.data;
                                this.dataObj.zxzcDataTotal = res.data.data.total
                                this.noDataType_zxzc = '';
                            }

                        }else{
                            console.log('数据返回错误')
                            console.log(res)
                            if(res.data.status == -1&&res.data.msg){
                                this.$message({
                                    type:'warning',
                                    message:res.data.msg
                                })
                            }
                            this.noDataType_zxzc = '';
                        }
                    }else{
                        console.log('数据请求错误')
                        console.log(res)
                        this.noDataType_zxzc = '';
                    }
                    this.loadingObj.zxzcLoading = false
                }).catch(error => {
                    console.log('服务器请求错');
                    /*this.$message({
                        message:'服务器错误,请联系管理员!',
                        type:'error',
                        duration:'6000',
                    })*/
                    this.loadingObj.zxzcLoading = false
                    console.log(error);
                    this.noDataType_zxzc = '';
                })
            },
            //最新政策 - 解读
            getZxzcDataJD(){
                this.loadingObj.zxzcLoading = true
                let url = '/retrieve/getJiedu';
                let data = {
                    page:this.keydata.pageZxzc,
                    size: 10,
                    operator: true,
                    isIncludeFiles: 0,
                    searchType:"time"
                    //jieduTypes: '1,2,3'
                };
                let isArticleNo = false
                //搜索
                if(this.keydata.searchType){
                    let typeArr = this.searchSupType
                    data[typeArr[this.keydata.searchType]] = this.keydata.searchKey
                    if(typeArr[this.keydata.searchType] == 'articleNo'){
                        //isArticleNo = true;
                    }
                }
                doGet(url,data).then(res => {
                    if(res.status == 200){
                        if(res.data.status == 0){
                            if(isArticleNo){
                                this.dataObj.zxzcDataList = [];
                                this.dataObj.zxzcDataTotal = 0
                                this.noDataType_zxzc = 'articleNo'
                            }else{
                                this.dataObj.zxzcDataList = res.data.data.data;
                                this.dataObj.zxzcDataTotal = res.data.data.total
                                this.noDataType_zxzc = '';
                            }
                            // this.dataObj.zxzcDataList = res.data.data.data;
                            // this.dataObj.zxzcDataTotal = res.data.data.total
                        }else{
                            console.log('数据返回错误')
                            console.log(res)
                            if(res.data.status == -1&&res.data.msg){
                                this.$message({
                                    type:'warning',
                                    message:res.data.msg
                                })
                            }
                            this.noDataType_zxzc = '';
                        }
                    }else{
                        console.log('数据请求错误')
                        console.log(res)
                        this.noDataType_zxzc = '';
                    }
                    this.loadingObj.zxzcLoading = false
                }).catch(error => {
                    console.log('服务器请求错');
                    /*this.$message({
                        message:'服务器错误,请联系管理员!',
                        type:'error',
                        duration:'6000',
                    })*/
                    this.loadingObj.zxzcLoading = false
                    console.log(error);
                    this.noDataType_zxzc = '';
                })
            },
            // 新媒体 - 解读
            getNewData(){
                this.noDataType_jd = 'loading'
                let that = this
                this.loadingObj.newLoading = true
                let url ='/retrieve/hotList';

                let data = {
                    page: this.keydata.pageNew,
                    limit: 10,
                }
                //判断类型
                if(this.keydata.newIndex ==1){
                    data.assort = 27;
                }else if(this.keydata.newIndex ==2){
                    data.assort =30;
                }else{
                    data.assort = 31;
                }

                let isArticleNo = false
                //搜索
                if(this.keydata.searchType){
                    let typeArr = this.searchSupType
                    let thatSearchType = this.keydata.searchType
                    if(thatSearchType == 3){
                        thatSearchType =1
                    }
                    data[typeArr[thatSearchType]] = this.keydata.searchKey
                    if(typeArr[this.keydata.searchType] == 'articleNo'&&this.keydata.searchKey!=''){
                        isArticleNo = true;
                    }
                }

                doGet(url,data).then(res=> {
                    if(res.status == 200){
                        if(res.data.status == 0){

                            if(isArticleNo){
                                this.dataObj.newDataList = [];
                                this.dataObj.newDataTotal = 0;
                                this.noDataType_jd = 'articleNo'
                            }else{
                                this.dataObj.newDataList = res.data.rows;
                                this.dataObj.newDataTotal = res.data.total;
                                this.noDataType_jd = ''
                            }
                            if(that.keydata.searchKey&&that.keydata.searchKey!=''){
                                for(let i=0;i<this.dataObj.newDataList.length;i++){
                                    //获取当前title
                                    let thatTitle = this.dataObj.newDataList[i].title
                                    let redText = "<em style='font-style:normal;color:#ff0000;'>" + this.keydata.searchKey +"</em>"
                                    thatTitle = thatTitle.replaceAll(this.keydata.searchKey,redText);
                                    that.dataObj.newDataList[i].title = thatTitle
                                }
                            }

                        }else{
                            console.log('数据返回错误')
                            console.log(res)
                            if(res.data.status == -1&&res.data.msg){
                                this.$message({
                                    type:'warning',
                                    message:res.data.msg
                                })
                            }
                            this.noDataType_jd = ''
                        }
                    }else{
                        console.log('数据请求错误')
                        console.log(res)
                        this.noDataType_jd = ''
                    }
                    this.loadingObj.newLoading = false
                }).catch(error => {
                    console.log('服务器请求错');
                    /*this.$message({
                        message:'服务器错误,请联系管理员!',
                        type:'error',
                        duration:'6000',
                    })*/
                    this.loadingObj.newLoading = false
                    this.noDataType_jd = ''
                    console.log(error);
                })
            },
            //点击详情事件
            dataHandle(tid,type,title,index){

                //获取存储里的信息 是否为第一次 因为未做初始存储 获取值为null 如果有值则是判断非第一次
                //let isFirst = sessionStorage.getItem("isFirst");
                let isFirst = 0
                if(isFirst){
                    //判断用户存储 cookie信息
                    let loginCode = this.$cookies.get('loginCode')
                    if(loginCode == null){
                        let detailObj = {
                            Oid:tid,
                            Otype:type,
                            isPhb:false
                        }
                        let ewmObj = {
                            status:true
                        }
                        this.$store.commit('addDetailInfo',detailObj)
                        this.$store.commit('addEwmStatus',ewmObj)
                    }else{
                        //获取存储的cookie的code
                        console.log(loginCode)
                        console.log(loginCode.code)
                        let val = loginCode.code
                        //验证是否过期
                        let url = '/exit';
                        let data = val
                        doGetCode(url,data).then(res => {
                            console.log(res)
                            if(res.status == 200){
                                if(res.data.status == 0 ){
                                    //存入cookie 过期时间为1天
                                    let cookieObj = {
                                        code:val,
                                        expires:1
                                    }
                                    this.$cookies.set('loginCode',cookieObj)
                                    this.goDetail(tid,type)
                                }else{
                                    //错误提示
                                    this.$message.error(res.data.msg);
                                    //如果是过期则重新验证
                                    if(res.data.status == -1){
                                        let detailObj = {
                                            Oid:tid,
                                            Otype:type,
                                            isPhb:false
                                        }
                                        let ewmObj = {
                                            status:true
                                        }
                                        this.$store.commit('addDetailInfo',detailObj)
                                        this.$store.commit('addEwmStatus',ewmObj)
                                    }
                                }
                            }else{
                                this.$message.error('验证码验证失败,请稍后重试!');
                            }
                            this.loading = false
                        }).catch(error => {
                            console.log(error)
                            this.loading = false
                            this.$message.error('验证码验证失败,请稍后重试!');
                        })
                    }
                }else{
                    sessionStorage.setItem("isFirst", "second");
                    this.goDetail(tid,type)
                }

            },
            //跳转详情
            goDetail(tid,type){
                let obj = {
                    id:tid,
                    site:'detail',
                    keyWord:this.keydata.searchKey
                }
                if(this.keydata.searchType == 2){
                    obj.wh = this.keydata.searchKey
                }
                let routeData = this.$router.resolve({
                    name: "Detail",
                    query:obj
                });

                //存储cookie操作
                let cookieObj  = {
                    type:'one',
                    typeChild:'one',
                    typeChildChild:'one',
                }

                if(type=='swwd'){
                    cookieObj.type = '税务问答';
                    cookieObj.typeChild = this.swwdSort[(this.keydata.swwdIndex - 1)].title;
                }else if(type == 'zxzc'){
                    cookieObj.type = '最新政策';
                    cookieObj.typeChild = this.zxzcSort[(this.keydata.zxzcIndex - 1)].title;
                }

                // let setCookie = JSON.stringify(cookieObj)
                // console.log(JSON.stringify(cookieObj))
                this.$cookies.set('addressInfo',cookieObj)
                window.open(routeData.href, '_blank');
            },
            //新媒体点击详情事件
            dataHandleJD(tUrl,index){
                //获取存储里的信息 是否为第一次 因为未做初始存储 获取值为null 如果有值则是判断非第一次
                //let isFirst = sessionStorage.getItem("isFirst");
                let isFirst = 0
                if(isFirst){
                    //判断用户存储 cookie信息
                    let loginCode = this.$cookies.get('loginCode')
                    if(loginCode == null){
                        let detailObj = {
                            Oid:tUrl,
                            Otype:'',
                            isPhb:true,
                        }
                        let ewmObj = {
                            status:true
                        }
                        this.$store.commit('addDetailInfo',detailObj)
                        this.$store.commit('addEwmStatus',ewmObj)
                    }else{
                        //获取存储的cookie的code
                        console.log(loginCode)
                        console.log(loginCode.code)
                        let val = loginCode.code
                        //验证是否过期
                        let url = '/exit';
                        let data = val
                        doGetCode(url,data).then(res => {
                            console.log(res)
                            if(res.status == 200){
                                if(res.data.status == 0 ){
                                    //存入cookie 过期时间为1天
                                    let cookieObj = {
                                        code:val,
                                        expires:1
                                    }
                                    this.$cookies.set('loginCode',cookieObj)
                                    this.goDetailPHB(tUrl)
                                }else{
                                    //错误提示
                                    this.$message.error(res.data.msg);
                                    //如果是过期则重新验证
                                    if(res.data.status == -1){
                                        let detailObj = {
                                            Oid:tUrl,
                                            Otype:'',
                                            isPhb:true,
                                        }
                                        let ewmObj = {
                                            status:true
                                        }
                                        this.$store.commit('addDetailInfo',detailObj)
                                        this.$store.commit('addEwmStatus',ewmObj)
                                    }
                                }
                            }else{
                                this.$message.error('验证码验证失败,请稍后重试!');
                            }
                            this.loading = false
                        }).catch(error => {
                            console.log(error)
                            this.loading = false
                            this.$message.error('验证码验证失败,请稍后重试!');
                        })
                    }

                }else{
                    sessionStorage.setItem("isFirst", "second");
                    this.goDetailPHB(tUrl)
                }
            },
            goDetailPHB(tUrl){
                window.open(tUrl)
            },
            //点击更多事件
            moreHandle(index){
                let sSup = '';
                if(index == 1){
                    let tnum  = this.keydata.swwdIndex - 1
                    sSup =  this.swwdSort[tnum].title
                }else if(index == 2){
                    let tnum  = this.keydata.zxzcIndex - 1
                    sSup = this.zxzcSort[tnum].title
                }else if(index == 3){
                    let tnum  = this.keydata.newIndex - 1
                    sSup = this.newSort[tnum].title
                }
                let vuexObj = {
                    type:index + 5,
                    typeSup:sSup
                }
                this.$store.commit('addTypeData',vuexObj)
                //路由跳转参数 
                //this.keydata.searchKey = curVal.key;
                //this.keydata.searchType = curVal.type;
                let obj = {
                    type:index,
                   
                }
                
                //判断是否存在搜索参数
                if(this.keydata.searchKey != ''){
                    let pObj = {
                        keyWord:this.keydata.searchKey,
                        searchType:this.keydata.searchType,
                    }
                    this.$router.push(
                        {
                            name:'More',
                            query:obj,
                            params:pObj,
                        }
                    )
                }else{
                    this.$router.push(
                        {
                            //path: '/more',
                            name:'More',
                            query:obj,
                        }
                    )
                }
                
            },

            //分页切换 税务问答
            handleSizeChangeSwwd(){
                this.keydata.pageSwwd = val
                this.getSwwdData();
            },
            handleCurrentChangeSwwd(val){
                this.keydata.pageSwwd = val
                this.getSwwdData();
            },
            //分页切换 最新政策
            handleSizeChangeZxzc(val){
                this.keydata.pageZxzc = val
                if(this.keydata.zxzcIndex!=4){
                    this.getZxzcData();
                }else{
                    this.getZxzcDataJD()
                }
            },
            handleCurrentChangeZxzc(val){
                this.keydata.pageZxzc = val
                if(this.keydata.zxzcIndex!=4){
                    this.getZxzcData();
                }else{
                    this.getZxzcDataJD()
                }
            },
            //分页切换 新媒体
            handleSizeChangeNew(val){
                this.keydata.pageNew = val
                this.getNewData();
            },
            handleCurrentChangeNew(val){
                this.keydata.pageNew = val
                this.getNewData();
            },
        }
    }
</script>

<style lang="scss" scoped>
    .allNotData{
        text-align: center;
        margin-top:100px;
        font-size: 18px;
        color:#888888;
        font-family: SourceHanSansCN-Medium;
    }
    /**顶部导航样式 start*/
    .topNav{
        width:100%;
        height: 48px;
        box-sizing: border-box;
        border-bottom:2px solid #009cff;
        .topNavleft{
            width:140px;
            height:48px;
            color:#ffffff;
            line-height: 48px;
            text-align: center;
            font-size: 20px;
            float: left;
            background-image: url('~@/assets/images/leftNavImg.png');
        }
        .sortTabs{
            width: calc(100% - 200px);
            float: left;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            .sortTabsList{
                height:48px;
                line-height: 48px;
                color:#888888;
                font-family: SourceHanSansCN-Medium;
                font-size:18px;
                margin-left:25px;
                float: left;
                cursor:pointer;

            }
            .sortTabsListCheck{
                color:#009cff;
            }
        }
        /**更多按钮*/
        .moreData{
            width:32px;
            height: 48px;
            line-height: 48px;
            font-size:16px;
            float: right;
            color:#b8b8b8;
            cursor: pointer;
            overflow: hidden;
            font-weight: bold;
        }
    }
    /**顶部导航样式 end*/

    /**数据列表部分 税务问答 start*/
    .swwdDataShow{
        /**数据展示头部*/
        .swwdDataHead{
            width:100%;
            height:36px;
            line-height: 36px;
            background: #f0f0f0;
            text-align: center;
            font-size: 16px;
            font-weight: 700;
            color:#0045ad;
            .swwdDataHeadTitle{
                width:calc(100% - 120px - 20px);
                float: left;
                margin-right: 20px;
            }
            .swwdDataHeadDanWei{
                width:200px;
                float: left;
                text-align: center;
                margin-right: 20px;
            }
            .swwdDataHeadTime{
                width:120px;
                float: left;
                text-align: center;
            }
        }
        /**数据展示列表*/
        .swwdDataListBox{
            min-height: 34px;
            overflow: hidden;
            /*padding:9px 0 10px 0;*/
            color:#222222;
            cursor: pointer;
            .showDataList{
                height:16px;
                margin-top:18px;
            }
            .showDataListNot{
                height:16px;
                line-height: 16px;
                text-align: center;
                margin-top:18px;
                /*line-height: 50px;*/
            }
            .showDataListTitle{
                width:calc(100% - 120px - 20px);
                /*height:36px;*/
                /*line-height: 36px;*/
                height: 16px;
                line-height: 16px;
                font-size: 16px;
                float: left;
                overflow:hidden;
                text-overflow:ellipsis;
                white-space:nowrap;
            }
            .showDataListDanWei{
                width: 200px;
                /*height:36px;*/
                /*line-height: 36px;*/
                height: 16px;
                line-height: 16px;
                font-size: 16px;
                float: left;
                margin-left:20px;
                /*text-align: center;*/
                overflow:hidden;
                text-overflow:ellipsis;
                white-space:nowrap;
            }
            .showDataListTime{
                width: 120px;
                /*height:36px;*/
                /*line-height: 36px;*/
                height: 16px;
                line-height: 16px;
                font-size: 16px;
                float: left;
                margin-left:20px;
                text-align: center;
            }
        }
    }
    /**数据列表部分 税务问答 end*/

    /**数据列表部分 最新政策 start*/
    .zxzcDataShow{
        /**数据展示头部*/
        .zxzcDataHead{
            width:100%;
            height:36px;
            line-height: 36px;
            background: #f0f0f0;
            text-align: center;
            font-size: 16px;
            font-weight: 700;
            color:#0045ad;
            .zxzcDataHeadTitle{
                width:calc(100% - 120px - 160px - 20px);
                float: left;
            }
            .zxzcDataHeadWh{
                /*width:240px;*/
                width:160px;
                float: left;
                margin-left:20px;
            }
            .zxzcDataHeadTime{
                width:120px;
                float: left;
                /*margin-left:20px;*/
            }
        }
        /**数据展示列表*/
        .zxzcDataListBox{
            /*min-height: 60px;*/
            min-height: 34px;
            overflow: hidden;
            /*padding:9px 0 10px 0;*/
            color:#222222;
            cursor: pointer;
            .showDataListNot{
                height:16px;
                line-height: 16px;
                text-align: center;
                margin-top:18px;
                /*line-height: 50px;*/
            }
            .showDataList{
                height:16px;
                margin-top:18px;
            }
            .showDataListTitle{
                /*width:640px;*/
                width:calc(100% - 120px - 160px - 20px);
                /*height:36px;*/
                /*line-height: 36px;*/
                height: 16px;
                line-height: 16px;
                font-size: 16px;
                float: left;
                overflow:hidden;
                text-overflow:ellipsis;
                white-space:nowrap;
            }
            .showDataListWh{
                /*width:240px;*/
                width:160px;
                float: left;
                /*height:36px;*/
                /*line-height: 36px;*/
                height: 16px;
                line-height: 16px;
                /*text-align: center;*/
                font-size: 16px;
                margin-left:20px;
                overflow:hidden;
                text-overflow:ellipsis;
                white-space:nowrap;
            }
            .showDataListWhc{
                /*width:240px;*/
                width:160px;
                float: left;
                /*height:36px;*/
                /*line-height: 36px;*/
                height: 16px;
                line-height: 16px;
                text-align: center;
                font-size: 16px;
                margin-left:20px;
                overflow:hidden;
                text-overflow:ellipsis;
                white-space:nowrap;
            }
            .showDataListTime{
                width: 120px;
                /*height:36px;*/
                /*line-height: 36px;*/
                height: 16px;
                line-height: 16px;
                font-size: 16px;
                float: right;
                text-align: center;
                /*margin-right:10px;*/
            }
        }
    }
    /**数据列表部分 最新政策 end*/

    /**数据列表部分 新媒体 start*/
    .newDataShow{
        /**数据展示头部*/
        .newDataHead{
            width:100%;
            height:36px;
            line-height: 36px;
            background: #f0f0f0;
            text-align: center;
            font-size: 16px;
            font-weight: 700;
            color:#0045ad;
            .newDataHeadTitle{
                width:640px;
                width:calc(100% - 120px - 50px - 120px - 10px);
                float: left;
            }
            .newDataHeadGzh{
                /*width:140px;*/
                width:120px;
                float: left;
                margin-left:10px;
            }
            .newDataHeadGYdl{
                width:50px;
                float: left;
                /*margin-left:20px;*/
            }
            .newDataHeadTime{
                width:120px;
                float: left;
                /*margin-left:20px;*/
            }
        }
        /**数据展示列表*/
        .newDataListBox{
            /*min-height: 60px;*/
            min-height: 34px;
            overflow: hidden;
            /*padding:9px 0 10px 0;*/
            color:#222222;
            cursor: pointer;
            .showDataList{
                height:16px;
                margin-top:18px;
            }
            .showDataListNot{
                height:16px;
                line-height: 16px;
                text-align: center;
                margin-top:18px;
                /*line-height: 50px;*/
            }
            .showDataListTitle{
                width:640px;
                width:calc(100% - 120px - 50px - 120px - 10px);
                /*height:36px;*/
                /*line-height: 36px;*/
                height: 16px;
                line-height: 16px;
                font-size: 16px;
                float: left;
                overflow:hidden;
                text-overflow:ellipsis;
                white-space:nowrap;
            }
            .showDataListGzh{
                width:120px;
                float: left;
                /*height:36px;*/
                /*line-height: 36px;*/
                height: 16px;
                line-height: 16px;
                font-size: 16px;
                margin-left:10px;
                overflow:hidden;
                text-overflow:ellipsis;
                white-space:nowrap;
                text-align: center;
            }
            .showDataListYdl{
                width:50px;
                float: left;
                /*height:36px;*/
                /*line-height: 36px;*/
                height: 16px;
                line-height: 16px;
                font-size: 16px;
                /*margin-left:20px;*/
                text-align: center;
            }
            .showDataListTime{
                width: 120px;
                /*height:36px;*/
                /*line-height: 36px;*/
                height: 16px;
                line-height: 16px;
                font-size: 16px;
                float: right;
                text-align: center;
                /*margin-right:10px;*/
                text-align: center;
            }
        }
    }
    /**数据列表部分 最新政策 end*/

    .division{
        /*width:1050px;*/
        width:860px;
        height:2px;
        background: #f0f0f0;
        /*margin:20px 0px 25px 0px;*/
        margin:18px 0px 25px 0px;
        overflow: hidden;
        display: block;
    }
    .fenyebox{
        text-align: center;
        margin-bottom: 2px;
        overflow: hidden;
        margin-top:20px;
    }

    /**默认样式 */
    .focusing {
        border:none;
        outLine :-webkit-focus-ring-color auto 0px;
    }

    .zhezhao{
        position: fixed;
        top:0;
        left:0;
        width:100%;
        height:100vh;
        background: rgba(0,0,0,0.2);
        .zhezhaoWhiteBox{
            width:500px;
            height:170px;
            background: #ffffff;
            border-radius: 30px;
            margin:200px auto;
            text-align: center;
            .zhezhaotext{
                width:100%;
                height:50px;
                line-height: 50px;
                font-size:16px;
            }
            .zhezhaotextBig{
                width:100%;
                height:50px;
                line-height: 50px;
                font-size:22px;
            }
        }
    }
    .newZhaoZhao{
        position: fixed;
        top:0;
        left:0;
        width:100%;
        height:100vh;
        .zztextBox{
            width:300px;
            height:50px;
            line-height: 50px;
            background: rgba(0,0,0,0.8);
            border-radius: 10px;
            margin:200px auto;
            text-align: center;
            color:#ffffff;
        }
    }

    .hiddenClass{
        display: none;
    }
</style>
