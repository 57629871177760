import axios from 'axios'
import qs from 'qs'
const http = process.env.NODE_ENV === 'development' ? '/api/taxContentQuery' : '/api/taxContentQuery'
const phbApi = process.env.NODE_ENV === 'development' ? '/phbApi' : '/phbApi'
import JSONbig from 'json-bigint'


export function doGet(url, data) {
    return axios({
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        url: http + url,
        method: 'get',
        params: data
    })
}
//调用验证码验证时的get接口
export function doGetCode(url, data) {
    return axios({
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        url: http + url + '/' + data,
        method: 'get',
    })
}
export function doPost(url, data) {
    return axios({
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        url: http + url,
        method: 'post',
        data: data,
    })
}
export function doPostDetail(url, data) {
    return axios({
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        url: http + url,
        method: 'post',
        params: data,
    })
}
export function doGetPhb(url, data) {
    return axios({
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        url: phbApi + url,
        method: 'get',
        params: data
    })
}
export function doPostPhb(url, data) {
    return axios({
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        url: phbApi + url,
        method: 'post',
        params: data
    })
}
export function doPostJson(url, data) {
    return axios({
        headers: {
            'Content-Type': 'application/json; charset=utf-8'
        },
        url: http + url,
        method: 'post',
        data: JSON.stringify(data),
    })
}

export function fjlist(data) { //附件列表
    return axios({
        // baseURL:'',
        url: '/cms/directive/contentFileList?absoluteURL=false',
        method: 'get',
        params: data,
        transformResponse: [
            function(res) {
                let json = JSON.parse(JSON.stringify(JSONbig.parse(res)));
                return json;
            }
        ],
    })
}