<template>
    <div class="ewmApp" v-loading="loading">
        <!--二维码展示部分-->
        <div class="ewmView">
            <!--文字描述-->
            <div class="descView">
                <div class="textView">
                    <span class="descText">1.请使用微信扫码二维码，关注中税答疑公众号；</span>
                </div>
                <div class="textViewTwo">
                    <span class="descText">2.在公众号内回复关键词'1'，在下方验证码输入框中输入收到的4位数验证码,完成验证后即可使用本网站。</span>
                </div>
                <div class="textView">
                    <span class="blueText">注：</span>
                    <span class="descText">验证码当天有效，过期请重新操作第2步生成新验证码继续使用本网站。</span>
                </div>
            </div>
            <!--下面二维码和验证码-->
            <div class="ewmBottom">
                <!--左侧二维码-->
                <div class="ewmImg">
                    <img :src="ewmImg" alt="">
                </div>
                <!--右侧验证码-->
                <div class="yzmView">
                    <!--验证码文字-->
                    <div class="yzmViewTopBox">
                        <div class="yzmViewTopText">请输入验证码</div>
                    </div>
                    <!--输入框-->
                    <div class="yzmViewInput">
                        <div class="yzmViewInputBorder">
                            <el-input v-model="yzmValue"  maxlength='10' clearable></el-input>
                        </div>
                    </div>
                    <!--按钮-->
                    <div class="yzmViewButtom" @click="submitHandle">完&nbsp;成</div>
                </div>
            </div>
            <!--关闭按钮-->
            <div class="closeView" @click="colseHandle">
                <img :src="closeIcon" alt="">
            </div>

        </div>
    </div>
</template>

<script>

    String.prototype.replaceAll  = function(s1,s2){
        return this.replace(new RegExp(s1,"gm"),s2);
    }

    //引入图片
    import ewmImg from '@/assets/images/zsdyewm.png'
    import closeIcon from '@/assets/images/closeIcon.png'

    //引入接口
    //接口
    import { doGetCode  } from "@/api/request";


    export default {
        name: "ewm.vue",
        data(){
            return{
                ewmImg,
                closeIcon,
                //验证码
                yzmValue:'',
                //loading加载
                loading:false,
            }
        },
        created(){

        },
        methods:{
            //提交验证码验证
            submitHandle(){
                //验证码去除空格
                let val = this.yzmValue.replaceAll(' ','')
                //判断是否输入
                if(val == ''){
                    this.$message({
                        type:"warning",
                        message:'请输入验证码~'
                    })
                }else{
                    //判断验证码类型是否为数字
                    let isnum = /^\d+$/.test(val);
                    if(isnum == true){
                        if(val.length!=4){
                            this.$message({
                                type:"warning",
                                message:'请输入正确四位数验证码~'
                            })
                        }else{
                            //调用接口 验证码验证
                            this.submitCodeHandle(val);
                        }
                    }else{
                        this.$message({
                            type:"warning",
                            message:'您的验证码格式不正确~'
                        })
                    }

                }
            },
            //关闭按钮
            colseHandle(){
                if(this.loading == true){
                    return
                }
                let ewmObj = {
                    status:false
                }
                this.$store.commit('addEwmStatus',ewmObj)
            },
            //验证码提交
            submitCodeHandle(val){
                this.loading = true
                let url = '/exit';
                let data = val
                doGetCode(url,data).then(res => {
                    console.log(res)
                    if(res.status == 200){
                        if(res.data.status == 0 ){
                            //存入cookie 过期时间为1天
                            let cookieObj = {
                                code:val,
                                expires:1
                            }
                            this.$cookies.set('loginCode',cookieObj)
                            //点击确认按钮事件
                            this.$store.commit('addCodeOK',{ status:'ok'})

                            //关闭事件
                            let ewmObj = {
                                status:false
                            }
                            this.$store.commit('addEwmStatus',ewmObj)
                        }else{
                            this.$message.error(res.data.msg);
                        }
                    }else{
                        this.$message.error('验证码验证失败,请稍后重试!');
                    }
                    this.loading = false
                }).catch(error => {
                    console.log(error)
                    this.loading = false
                    this.$message.error('验证码验证失败,请稍后重试!');
                })
            }

        }

    }
</script>

<style lang="scss" scoped>
.ewmApp{
    width:720px;
    height:400px;
    border-radius: 16px;
    box-shadow: 0px 1px 4px 0px #b4b4b4;
    background: #ffffff;
    margin:240px auto;
    .ewmView{
        width:100%;
        height:100%;
        padding:0 32px;
        box-sizing: border-box;
        position: relative;
        .descView{
            width:100%;
            height:150px;
            padding-top:38px;
            box-sizing: border-box;
            .textView{
                width:100%;
                height:16px;
                line-height: 16px;
                font-size: 16px;
                font-family: SourceHanSansCN-Bold;
                font-family: SourceHanSansCN-Regular;
                margin-bottom:6px;
                .descText{
                    color:#222222;
                }
                .blueText{
                    color:#009cff;
                }
            }
            .textViewTwo{
                width:100%;
                padding: 0;
                line-height: 16px;
                font-size: 16px;
                font-family: SourceHanSansCN-Bold;
                font-family: SourceHanSansCN-Regular;
                margin-bottom:6px;
            }
        }
        .ewmBottom{
            width:100%;
            .ewmImg{
                width:200px;
                height:200px;
                float: left;
                margin-left:48px;
                img{
                    width:100%;
                }
            }
            .yzmView{
                width:300px;
                height:160px;
                float:right;
                margin-top:20px;
                margin-right:18px;
                .yzmViewTopBox{
                    width:100%;
                    height:50px;
                    .yzmViewTopText{
                        width:100%;
                        height:18px;
                        line-height: 18px;
                        text-align: center;
                        font-size: 18px;
                        color:#009cff;
                        font-weight: 500;
                        font-family: SourceHanSansCN-Bold;
                    }
                }
                .yzmViewInput{
                    width:100%;
                    height:70px;
                }
                .yzmViewInputBorder{
                    width:100%;
                    height:40px;
                    overflow: hidden;
                    border: 2px solid #ededed;
                }
                .yzmViewInput ::v-deep .el-input__inner{
                    border: none;
                    border-radius:0;
                    width:100%;
                    box-sizing: border-box;
                }
                .yzmViewButtom{
                    width:80px;
                    height:32px;
                    line-height: 32px;
                    color:#ffffff;
                    background: #009cff;
                    border-radius:4px;
                    text-align: center;
                    float: right;
                    margin-right:110px;
                    cursor:pointer;
                }
            }
        }
        .closeView{
            position: fixed;
            top:652px;
            left:calc(50% - 25px);
            cursor:pointer;
        }

    }
}

</style>
