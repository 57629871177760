<template>
  <div id="indexView">
    <!--顶部模块 主要为搜索-->
    <div class="top">
      <!--搜索部分 logo 搜索框 搜索类型-->
      <div class="search">
        <div class="goodView" v-if="false">
          <img :src="imgs.good" alt="" />
        </div>
        <!--左侧logo-->
        <div class="leftLogo">
          <img :src="imgs.logo" alt="" />
        </div>
        <!--中间搜索部分-->
        <div class="centerSearch">
          <!--搜索框-->
          <div class="searchTop">
            <input
              class="searchInput"
              type="text"
              placeholder="请输入您要查询政策的标题、文号或正文内容"
              @focus="keyData.isSearch = true"
              @blur="blurFunction"
              :class="
                keyData.searchLenovo.length != 0
                  ? 'searchInputExpand'
                  : 'searchInputOrdinary'
              "
              @input="fillHotWords()"
              v-model="keyData.searchValue"
            />
            <!--左侧搜索放大镜图标-->
            <div class="searchIcon">
              <img :src="imgs.searchIcon" alt="" />
            </div>
            <!--<div @click="searchHandle" class="searchButton">搜索</div>-->
            <div @click="searchBtn" class="searchButton">搜索</div>
            <!--搜索联想-->
            <div
              class="searchLenovoView"
              v-if="keyData.searchLenovo.length != 0"
            >
              <div
                class="searchLenovoList"
                v-for="(item, index) in keyData.searchLenovo"
                @click="lenovoHandle(item.title)"
                :key="index"
                v-html="item.highlight"
              ></div>
            </div>
          </div>
          <!--搜索类型-->
          <div class="searchType">
            <div
              class="searchTypeList"
              v-for="(item, index) in searchCheck"
              :key="index"
            >
              <div class="typeListLeft">
                <img
                  v-if="item.value == keyData.searchSup"
                  :src="imgs.checkB"
                  alt=""
                />
                <img
                  @click="searchCheckHandle(item.value)"
                  v-else
                  :src="imgs.checkA"
                  alt=""
                />
              </div>
              <span>{{ item.name }}</span>
            </div>
          </div>
        </div>
        <!--右侧的文字-->
        <div class="rightText">
          <div class="yellowText">20年坚持</div>
          <div class="whiteText">税务内容运维服务</div>
          <!--                    <p class="yellowText">20年坚持</p>-->
          <!--                    <p class="whiteText">税务内容运维服务</p>-->
        </div>
      </div>
    </div>
    <!--tabs切换-->
    <div class="tabsView">
      <div class="tabsViewM">
        <div
          class="tabsListBox"
          v-for="(item, index) in tabsArr"
          :key="index"
          :class="item.index == keyData.tabsIndex ? 'tabsListBoxBG' : ''"
          :id="index == 5 ? '' : 'tabsListBoxR'"
          @click="tabsListHandle(item.index)"
        >
          <p>{{ item.name }}</p>
        </div>
      </div>
    </div>
    <!--主要数据展示部分-->
    <div class="dataShow">
      <!--左侧数据切换-->
      <div class="leftData" v-if="keyData.tabsIndex != 99">
        <div v-if="keyData.tabsIndex != 99">
          <!--导航 您的位置-->
          <div class="addressNav" v-if="keyData.tabsIndex != 1 && keyData.site">
            <span class="Uaddress">您的位置: </span>
            <span @click="goIndex">首页</span>
            <span> > </span>
            <span>{{ keyData.cookieAddress.type }}</span>
            <span
              v-if="
                keyData.cookieAddress.typeChild &&
                  keyData.cookieAddress.typeChild != ''
              "
              >-</span
            >
            <span>{{ keyData.cookieAddress.typeChild }}</span>
            <!--                    <span v-if="keyData.cookieAddress.typeChildChild!=''">-</span>-->
            <!--                    <span>{{ keyData.cookieAddress.typeChildChild }}</span>-->
          </div>
          <div
            class="addressNav shenglue"
            v-if="
              keyData.tabsIndex == 1 &&
                !keyData.site &&
                keyData.searchValueOK != ''
            "
          >
            <span class="Uaddress">您的位置: </span>
            <span @click="goIndex">首页</span>
            <span> > </span>
            <span>搜索结果</span>
            <span> > </span>
            <span>{{ searchCheck[keyData.searchSup - 1].name }}</span>
            <span> > </span>
            <el-tooltip
              class="item"
              effect="dark"
              :content="keyData.searchValueOK"
              placement="top-start"
            >
              <span>{{ keyData.searchValueOK }}</span>
            </el-tooltip>
          </div>
          <div
            class="addressNav"
            v-if="keyData.tabsIndex != 1 && !keyData.site"
          >
            <span class="Uaddress">您的位置: </span>
            <span @click="goIndex">首页</span>
            <span> > </span>
            <span>{{ addType[vuexType] }}</span>
            <span v-if="vuexTypeSup != ''">-</span>
            <span>{{ vuexTypeSup }}</span>
          </div>
        </div>
        <!--切换路由-->
        <router-view />
      </div>
      <!--关于我们 不展示右侧广告-->
      <div class="gywm" v-if="keyData.tabsIndex == 99">
        <!--切换路由-->
        <router-view />
      </div>
      <!--右侧固定数据-->
      <div class="rightData" v-if="keyData.tabsIndex != 99">
        <!--广告部分-->
        <div class="guanggaoView">
          <!--sb查和sb问-->
          <div
            class="ggBox"
            v-for="(item, index) in ggData"
            :key="index"
            @click="goQT(index)"
          >
            <p style="display: none">{{ item.name }}</p>
            <img :src="item.img" alt="" />
          </div>
        </div>
        <!--税务局官网部分-->
        <div class="gwView">
          <!--官网部分导航-->
          <div class="ggSideNav">
            <div class="ggSideNavBG">税务局官网</div>
          </div>
          <!--官网地址列表-->
          <div class="ggListBox">
            <p class="pp" @click="goTaxBureau('http://www.chinatax.gov.cn/')">
              国家税务总局
            </p>
            <div
              class="ggUrlList"
              v-for="(item, index) in taxBureauArr.array"
              :key="index"
              @click="goTaxBureau(item.url)"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--底部導航-->
    <div class="bottom">
      <img :src="imgs.bottomNav" alt="" />
    </div>
    <!--悬浮的二维码all-->
    <div class="fixedViewEWM" v-if="ewmStatus">
      <ewmView />

      <!--中间二维码部分-->
      <!--            <div class="ewmBox">-->
      <!--                <div class="ewm">-->
      <!--                    我是二维码-->
      <!--                </div>-->
      <!--                <div class="zsdyText">-->
      <!--                    <div class="zsdyTextP">请使用微信扫描二维码</div>-->
      <!--                    <div class="zsdyTextP">关注 <span id="zsdyTextSpan">中税答疑</span> 公众号</div>-->
      <!--                    <div class="zsdyTextP">即可查看全部内容</div>-->
      <!--                </div>-->
      <!--                <div class="closeEwm">-->
      <!--                    <div class="closeEwmBtn" @click="closeEwm">×</div>-->
      <!--                </div>-->
      <!--            </div>-->
    </div>
  </div>
</template>

<script>
const environment = process.env.NODE_ENV === 'development' ? 'dev' : 'prod'
//引入图片
import logo from '@/assets/images/zsdyLogo.png'
import sideNavImg from '@/assets/images/sideNavImg.png'
import searchIcon from '@/assets/images/searchIcon.png'
import checkA from '@/assets/images/checkA.png'
import checkB from '@/assets/images/checkB.png'
import bottomNav from '@/assets/images/bottomNav.png'
import sbc from '@/assets/images/sbc.png'
import sbw from '@/assets/images/sbw.png'
import good from '@/assets/images/good.png'

//引入组建
import ewmView from '@/views/common/ewm.vue'

//引入js数据
import jsArr from '@/assets/js/taxBureauArr.js'

//request
import { doPostDetail } from '@/api/request'

import { debounce } from 'lodash'

export default {
  name: 'index',
  components: {
    ewmView,
  },
  computed: {
    inputkey() {
      return this.keyData.searchValue
    },
    typeVuex() {
      return this.$store.state.typeData
    },
    //路由传递参数
    routeQueryFN() {
      return this.$route.query.site
    },
    //监听二维码展示状态
    ewmStatusFN() {
      return this.$store.state.ewmStatus
    },
  },
  watch: {
    typeVuex(curVal, oldVal) {
      this.vuexType = curVal.type
      this.vuexTypeSup = curVal.typeSup
      this.keyData.tabsIndex = curVal.type
      console.log(this.keyData.tabsIndex)

      let mbxObj = {
        vuexType: curVal.type,
      }
      // let setCookie = JSON.stringify(cookieObj)
      // console.log(JSON.stringify(cookieObj))
      this.$cookies.set('mbxInfo', mbxObj)
    },
    inputkey(curVal, oldVal) {
      //这里的curVal就是需要监听的值 1-最新政策 2-最新解读 3-最新答疑
      if (curVal != '' && this.keyData.isSearch) {
      } else {
        this.keyData.searchLenovo = []
      }
    },
    routeQueryFN(curVal, oldVal) {
      this.keyData.site = curVal
      //如果是在detail页面那个获取一下存储的cookie
      if (this.keyData.site) {
        let thatCookie = this.$cookies.get('addressInfo')
        console.log(thatCookie)
        //判断是否存在cookie 在复制链接打开当前页面时没有cookie会报错
        if (thatCookie == null) {
          console.log('1')
          this.addLoading = true
        } else {
          this.keyData.cookieAddress = this.$cookies.get('addressInfo')
        }
      }
    },
    //监听二维码是否显示
    ewmStatusFN(cur, old) {
      if (cur.status == true) {
        this.ewmStatus = true
      } else {
        this.ewmStatus = false
      }
    },
  },
  data() {
    return {
      //引入的图片
      imgs: {
        //中税答疑logo
        logo,
        //右侧边导航图标
        sideNavImg,
        //搜索图标
        searchIcon,
        //check选择
        checkA,
        checkB,
        bottomNav,
        good,
      },
      //定义的参数
      keyData: {
        //搜索框绑定的参数
        searchValue: '',
        searchValueOK: '',
        //当前选中的tabs
        tabsIndex: 1,
        //搜索联想
        searchLenovo: [],
        //是否在做搜索操作
        isSearch: false,
        //搜索分类选择
        searchSup: 1,
        site: null,
        cookieAddress: {},
      },
      ewmStatus: false,
      //搜索分类选择
      searchCheck: [
        {
          name: '搜标题',
          value: 1,
        },
        {
          name: '搜文号',
          value: 2,
        },
        {
          name: '搜正文',
          value: 3,
        },
      ],
      //tabs切换
      tabsArr: [
        {
          name: '首页',
          index: 1,
        },
        {
          name: '税种政策',
          index: 2,
        },
        {
          name: '行业法规',
          index: 3,
        },
        {
          name: '税收优惠',
          index: 4,
        },
        {
          name: '法院判例',
          index: 5,
        },
        {
          name: '关于我们',
          index: 99,
        },
      ],
      addType: [
        'giao',
        '首页',
        '税种政策',
        '行业法规',
        '税收优惠',
        '法院判例',
        '税务问答',
        '最新政策',
        '解读',
      ],
      vuexType: 1,
      vuexTypeSup: '',
      //对应路由信息
      routeArr: ['giao', 'index', 'szzc', 'hyss', 'ssyh', 'fypl', 'gywm'],
      //广告数据
      ggData: [
        {
          name: '随便查广告图',
          img: sbc,
          url1: 'https://zsdy-taxlaw-pc-daily.shui12366.com',
          url2: 'https://zsdy-taxlaw-pc.shui12366.com',
        },
        {
          name: '随便问广告图',
          img: sbw,
          url1: 'https://zsdy-sbw-web-daily.shui12366.com',
          url2: 'https://zsdy-sbw-web.shui12366.com',
        },
      ],
      //税局列表
      taxBureauArr: jsArr,
    }
  },
  created() {
    let thatRoute = window.location.href
    //获取最后一个 '/' 位置
    let startNum = thatRoute.lastIndexOf('/') + 1
    let hashStr = thatRoute.slice(startNum)
    if (this.routeArr.indexOf(hashStr) == 6) {
      this.keyData.tabsIndex = 99
    } else {
      this.keyData.tabsIndex = this.routeArr.indexOf(hashStr)
    }

    //掏出我的面包屑
    if (this.$cookies.get('mbxInfo')) {
      this.vuexType = this.$cookies.get('mbxInfo').vuexType
    }
  },
  methods: {
    //搜索联想词
    fillHotWords: debounce(function() {
      var _this = this
      this.moreStatus = false
      this.lenovoKeywordList = []
      this.lenovoKeyWords = []
      if (this.keyData.searchValue == '') {
        return
      }
      let url = '/retrieve/fillHotWords'
      let data = {
        name: _this.keyData.searchValue,
        pageSize: 10,
        pageNumber: 1,
      }
      doPostDetail(url, data)
        .then((res) => {
          if (res.status == 200) {
            if (res.data.status == 0) {
              this.keyData.searchLenovo = res.data.data.data
            } else {
              this.$message({
                message: '请联系前端开发!',
                type: 'warning',
              })
              console.log(res)
            }
          } else {
            this.$message({
              message: '请联系前端开发!',
              type: 'warning',
            })
            console.log(res)
          }
        })
        .catch((error) => {
          this.$message({
            message: '请联系管理员',
            type: 'warning',
          })
          console.log(error)
        })
    }, 500),

    blurFunction() {
      let that = this
      setTimeout(function() {
        that.keyData.isSearch = false
        that.keyData.searchLenovo = []
        that.searchHandle()
      }, 1000)
    },
    //搜索按钮
    searchBtn() {
      //判断输入框焦点 无焦点时调用接口 有焦点会在失去焦点时 调用接口
      if (this.keyData.isSearch == false) {
        this.searchHandle()
      }
    },
    //搜索点击事件
    searchHandle() {
      let trimKey = ''
      if (this.keyData.searchValue != '') {
        trimKey = this.keyData.searchValue.trim()
      }
      //这句是面包屑展示用的 搜索状态下
      this.keyData.searchValueOK = trimKey
      this.keyData.searchLenovo = []
      //传递的参数
      let searchObj = {
        key: trimKey,
        type: this.keyData.searchSup,
      }
      //路由传递搜索参数
      this.$store.commit('addSearchData', searchObj)
      //用于路由跳转 判断是否是详情页面 暂时固定调回首页进行搜索 (后续可能会需求不固定首页跳转)
      if (this.$route.query.site) {
        let vuexObj = {
          type: 1,
          typeSup: '',
        }
        this.$store.commit('addTypeData', vuexObj)
        this.$router.push({
          path: '/index',
        })
      }
    },
    //搜索联想词点击事件
    lenovoHandle(key) {
      this.keyData.searchValue = key
      this.keyData.searchLenovo = []
      this.keyData.isSearch = false
      this.searchHandle()
    },
    //搜标题 文号 正文
    searchCheckHandle(index) {
      this.keyData.searchSup = index
    },
    //tabs切换事件
    tabsListHandle(index) {
      if (index == 99) {
        //存储type
        if (index != 1) {
          let vuexObj = {
            type: index,
            typeSup: '',
          }
          this.$store.commit('addTypeData', vuexObj)
        }

        this.keyData.tabsIndex = index
        let pathStr = 'gywm'
        //操作
        this.$router.push({ path: pathStr })
      } else {
        //这句是面包屑展示用的 搜索状态下
        this.keyData.searchValueOK = this.keyData.searchValue
        //存储type
        if (index != 1) {
          let vuexObj = {
            type: index,
            typeSup: '',
          }
          this.$store.commit('addTypeData', vuexObj)
        }

        this.keyData.tabsIndex = index
        let pathStr = this.routeArr[index]
        //传递的参数
        let searchObj = {
          key: this.keyData.searchValue,
          type: this.keyData.searchSup,
        }
        //路由传递搜索参数
        this.$store.commit('addSearchData', searchObj)
        //操作
        this.$router.push({ path: pathStr })
      }
    },
    //关闭二维码
    closeEwm() {
      this.ewmStatus = false
    },
    //去首页
    goIndex() {
      this.tabsListHandle(1)
    },
    //去随便查或随便问
    goQT(index) {
      let val = ''
      if (environment == 'dev') {
        val = this.ggData[index].url1
      } else {
        val = this.ggData[index].url2
      }
      window.open(val)
    },
    //前往税务局
    goTaxBureau(val) {
      if (val != '') {
        window.open(val)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
/**顶部模块 start*/
.top {
  width: 100%;
  height: 240px;
  background-image: linear-gradient(120deg, #009cff 0%, #3f66f5 100%);
  position: relative;
  /**搜索部分*/
  .search {
    width: 1200px;
    margin: 0 auto;
    height: 240px;
    position: relative;
    .goodView {
      position: absolute;
      top: 6px;
      left: 0;
      width: 336px;
      img {
        width: 100%;
      }
    }
    /**左侧logo*/
    .leftLogo {
      box-sizing: border-box;
      padding-top: 96px;
      width: 252px;
      height: 200px;
      float: left;
      img {
        width: 100%;
        height: 60px;
      }
    }
    /**中间搜索部分*/
    .centerSearch {
      margin-left: 35px;
      box-sizing: border-box;
      padding-top: 100px;
      width: 620px;
      height: 240px;
      float: left;
      .searchTop {
        cursor: pointer;
        width: 100%;
        height: 50px;
        position: relative;
        /**搜索框*/
        .searchInput {
          width: 500px;
          padding-left: 48px;
          padding-right: 18px;

          line-height: 50px;
          height: 50px;
          box-sizing: border-box;
          color: #111111;
          float: left;
          font-size: 16px;
        }
        .searchInputOrdinary {
          border-radius: 25px 0 0 25px;
          border: 1px solid #b8b8b8;
        }
        .searchInputExpand {
          border-radius: 25px 0 0 0;
          border-top: 1px solid #b8b8b8;
          border-right: 1px solid #b8b8b8;
          border-left: 1px solid #b8b8b8;
        }
        /**搜索放大镜*/
        .searchIcon {
          position: absolute;
          left: 18px;
          top: 13px;
          width: 22px;
          height: 22px;
          img {
            width: 100%;
          }
        }
        /**搜索联想数据*/
        .searchLenovoView {
          width: 500px;
          overflow: hidden;
          box-sizing: border-box;
          border-right: 1px solid #b8b8b8;
          border-left: 1px solid #b8b8b8;
          border-bottom: 1px solid #b8b8b8;
          border-radius: 0px 0px 4px 4px;
          position: absolute;
          left: 0;
          top: 50px;
          background: #ffffff;
          padding-bottom: 5px;
          .searchLenovoList {
            color: #888888;
            font-size: 18px;
            box-sizing: border-box;
            padding-left: 48px;
            height: 34px;
            line-height: 34px;
          }
        }
        /**搜索按钮*/
        .searchButton {
          width: 120px;
          height: 50px;
          line-height: 50px;
          float: left;
          border-radius: 0 25px 25px 0;
          box-sizing: border-box;
          background: #0045ad;
          color: #ffffff;
          font-size: 20px;
          text-align: center;
          font-family: SourceHanSansCN-Medium;
          letter-spacing: 10px;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
        }
        .searchButton:hover {
          /**background: #4a88c7;*/
        }
      }
      /**标题 正文 文号 选择*/
      .searchType {
        margin-top: 14px;
        width: calc(85%);
        height: 20px;
        box-sizing: border-box;
        color: #ffffff;
        /**每个分类*/
        .searchTypeList {
          width: calc(100% / 3);
          height: 20px;
          line-height: 20px;
          float: left;
          font-size: 18px;
          box-sizing: border-box;
          padding-left: 80px;
          .typeListLeft {
            width: 20px;
            height: 20px;
            float: left;
            margin-right: 12px;
            img {
              /*margin-top:3px;*/
            }
          }
        }
      }
    }
    /**右侧文字*/
    .rightText {
      float: right;
      width: 293px;
      height: 200px;
      box-sizing: border-box;
      padding-top: 84px;
      text-align: right;
      font-style: oblique;
      .yellowText {
        height: 36px;
        line-height: 36px;
        font-family: SourceHanSansCN-Heavy;
        font-size: 36px;
        font-weight: bold;
        color: #ffd800;
      }
      .whiteText {
        height: 24px;
        line-height: 24px;
        color: #ffffff;
        font-family: SourceHanSansCN-Norma;
        font-size: 24px;
        margin-top: 10px;
      }
    }
  }
}
/**顶部模块 end*/

/**tabs切换部分 start*/
.tabsView {
  width: 100%;
  height: 64px;
  background: #0045ad;
  box-sizing: border-box;
  overflow: hidden;
  cursor: pointer;
  .tabsViewM {
    width: 1200px;
    height: 64px;
    margin: 0 auto;
    /**tabs列表样式*/
    .tabsListBox {
      width: 180px;
      height: 56px;
      line-height: 56px;
      text-align: center;
      margin-top: 4px;
      /*margin-right:22px;*/
      color: #ffffff;
      float: left;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      font-size: 20px;
    }
    #tabsListBoxR {
      margin-right: 24px;
    }
    /**选中的样式*/
    .tabsListBoxBG {
      background-image: url('~@/assets/images/checkTab.png');
      color: #0045ad;
      font-family: SourceHanSansCN-Bold;
      font-weight: 700;
    }
  }
}
/**tabs切换部分 end*/

/**主要数据展示部分 start*/
.dataShow {
  box-sizing: border-box;
  overflow: hidden;
  width: 1200px;
  margin: 0 auto;
  /**左侧数据切换*/
  .leftData {
    width: 860px;
    float: left;
    min-height: 200px;
    margin-top: 25px;
    overflow: hidden;
    /**您的位置导航*/
    .addressNav {
      cursor: pointer;
      height: 20px;
      line-height: 20px;
      font-size: 16px;
      margin-bottom: 5px;
      margin-top: 20px;
      .Uaddress {
        color: #1aa4ff;
      }
    }
  }
  /**右侧固定数据*/
  .rightData {
    width: 320px;
    float: right;
    min-height: 200px;
    margin-top: 34px;
    /**广告位*/
    .guanggaoView {
      width: 320px;
      height: 490px;
      .ggBox {
        width: 320px;
        height: 220px;
        margin-bottom: 25px;
        line-height: 220px;
        text-align: center;
        font-size: 30px;
        background: #009cff;
        cursor: pointer;
      }
    }
    /**官网位*/
    .gwView {
      width: 100%;
      overflow: hidden;
      /**导航*/
      .ggSideNav {
        width: 100%;
        height: 48px;
        box-sizing: border-box;
        border-bottom: 2px solid #009cff;
        .ggSideNavBG {
          width: 160px;
          height: 48px;
          color: #ffffff;
          font-size: 20px;
          text-align: center;
          line-height: 48px;
          background-image: url('~@/assets/images/sideNavImg.png');
        }
      }
      /**税局列表*/
      .ggListBox {
        box-sizing: border-box;
        padding: 6px 0 10px 20px;
        overflow: hidden;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        font-size: 16px;
        .pp {
          height: 34px;
          line-height: 34px;
          font-family: SourceHanSansCN-Medium;
          color: #222222;
          cursor: pointer;
        }
        .pp:hover {
          font-size: 17px;
        }
        .ggUrlList {
          width: 50%;
          height: 34px;
          line-height: 34px;
          font-family: SourceHanSansCN-Medium;
          color: #222222;
          float: left;
          cursor: pointer;
        }
        .ggUrlList:hover {
          font-size: 17px;
        }
      }
    }
  }
}
/**主要数据展示部分 end*/

/**底部导航图片*/
.bottom {
  width: 100%;
  margin-top: 40px;
  img {
    width: 100%;
  }
}
/**二维码*/
.fixedViewEWM {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  /*display: none;*/
}
.ewmBox {
  margin: 100px auto;
  width: 360px;
  height: 480px;
  background: #ffffff;
  padding-top: 60px;
  box-sizing: border-box;
  box-shadow: 0px 1px 4px 0px #b4b4b4;
  position: relative;
}
.ewm {
  width: 240px;
  height: 240px;
  background: red;
  line-height: 240px;
  text-align: center;
  color: #ffffff;
  margin-left: 60px;
}
.zsdyText {
  margin-top: 40px;
  .zsdyTextP {
    width: 100%;
    height: 24px;
    line-height: 24px;
    margin-bottom: 20px;
    text-align: center;
    color: #222222;
    font-size: 24px;
    font-family: SourceHanSansCN-Regular;
  }
  #zsdyTextSpan {
    color: #0045ad;
    font-size: 30px;
    font-family: SourceHanSansCN-Bold;
  }
}
.closeEwm {
  position: absolute;
  bottom: -66px;
  height: 50px;
  width: 360px;
  font-size: 40px;
  color: #ffffff;
  .closeEwmBtn {
    margin-left: 155px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    text-align: center;
    line-height: 46px;
    background: #e6e6e6;
    cursor: pointer;
  }
}
.shenglue {
  width: 1000px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
}

::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #b7b7b7;
  font-size: 16px;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #b7b7b7;
  font-size: 16px;
}

:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #b7b7b7;
  font-size: 16px;
}

.gywm {
  width: 1200px;
  padding-top: 45px;
  box-sizing: border-box;
  overflow: hidden;
}
</style>
