<template>
  <div id="app">
    <Home />
  </div>
</template>
<script>
    import Home from '@/views/index.vue'
    const AppUrl = process.env.NODE_ENV == 'development' ? "http://zsdy-tax-content-query-h5.daily.shui12366.com/" :
        "http://zsdy-tax-content-query-h5.shui12366.com/";
    export default {
        name: "app",
        components:{
            Home
        },
        data(){
            return{

            }
        },
        created() {
            if (this.isMobile()) {
               window.location.href = AppUrl+window.location.search
            } else {
                console.log(2)
                this.userVisit()
                this.userVisit2()
            }
        },

        methods:{
            isMobile(){
                let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
                return flag
                console.log(flag)
            },
            //百度统计
            userVisit(){
                var _hmt = _hmt || [];
                (function() {
                    var hm = document.createElement("script");
                    hm.src = "https://hm.baidu.com/hm.js?1594f9c3eb8b9c0c5ba9d6f3d1613733";
                    var s = document.getElementsByTagName("script")[0];
                    s.parentNode.insertBefore(hm, s);
                })();
            },
            //调用其他页面的统计
            userVisit2(){
                var _hmt = _hmt || [];
                (function() {
                    var hm = document.createElement("script");
                    hm.src = "https://hm.baidu.com/hm.js?0c9171993e88126777d71c4f82f2bc95";
                    var s = document.getElementsByTagName("script")[0];
                    s.parentNode.insertBefore(hm, s);
                })();
            }
        }
    }
</script>

<style lang="scss">

/**css重置样式*/
html,body,ol,ul,li,h1,h2,h3,h4,h5,h6,p,th,td,dl,dd,form,fieldset,legend,input,textarea,select{margin:0;padding:0;}
body{font-size:16px;font-family: ‘微软雅黑‘;}
ul,ol,li{list-style:none;}
h1,h2,h3,h4,h5,h6{font-size:16px;font-weight: normal;}
b,strong{ font-weight: normal;}
i,em{font-style: normal;}
a,u{text-decoration: none;}
img{border:0;display:block;}
input,fieldset{outline: none; border:0;}
.clear_fix{zoom:1;}

</style>
