import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
      //搜索参数
      searchData:{},
      //vuex存储type
      typeData:{},
      //vuex存储是否展示二维码状态
      ewmStatus:{},
      //详情信息
      detailInfo:{},
      //验证码点击确认
      codeOK:{}
  },
  mutations: {
      addSearchData(state,payload){
          state.searchData = payload
      },
      //存储type
      addTypeData(state,payload){
          state.typeData = payload
      },
      //存储展示二维码状态
      addEwmStatus(state,payload){
          state.ewmStatus = payload
      },
      //详情信息
      addDetailInfo(state,payload){
          state.detailInfo = payload
      },
      //验证码点击确认
      addCodeOK(state,payload){
          state.codeOK = payload
      },
  }
  ,
  actions: {
  },
  modules: {
  }
})
