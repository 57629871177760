
const array = [
    {
        name:'北京税务',
        url:'http://beijing.chinatax.gov.cn/bjswj/',
    },
    {
        name:'上海税务',
        url:'http://shanghai.chinatax.gov.cn/',
    },
    {
        name:'广东税务',
        url:'http://guangdong.chinatax.gov.cn/gdsw/',
    },
    {
        name:'深圳税务',
        url:'http://shenzhen.chinatax.gov.cn',
    },
    {
        name:'安徽税务',
        url:'http://anhui.chinatax.gov.cn',
    },
    {
        name:'甘肃税务',
        url:'http://gansu.chinatax.gov.cn',
    },
    {
        name:'广西税务',
        url:'http://guangxi.chinatax.gov.cn',
    },
    {
        name:'贵州税务',
        url:'http://guizhou.chinatax.gov.cn',
    },
    {
        name:'海南税务',
        url:'http://hainan.chinatax.gov.cn',
    },
    {
        name:'河北税务',
        url:'http://hebei.chinatax.gov.cn',
    },
    {
        name:'河南税务',
        url:'http://henan.chinatax.gov.cn',
    },
    {
        name:'黑龙江税务',
        url:'http://heilongjiang.chinatax.gov.cn',
    },
    {
        name:'湖北税务',
        url:'http://hubei.chinatax.gov.cn',
    },
    {
        name:'湖南税务',
        url:'http://hunan.chinatax.gov.cn',
    },
    {
        name:'江西税务',
        url:'http://jiangxi.chinatax.gov.cn',
    },
    {
        name:'吉林税务',
        url:'http://jilin.chinatax.gov.cn',
    },
    {
        name:'江苏税务',
        url:'http://jiangsu.chinatax.gov.cn',
    },
    {
        name:'辽宁税务',
        url:'http://liaoning.chinatax.gov.cn',
    },
    {
        name:'内蒙古税务',
        url:'http://neimenggu.chinatax.gov.cn',
    },
    {
        name:'宁夏税务',
        url:'http://ningxia.chinatax.gov.cn',
    },
    {
        name:'青海税务',
        url:'http://qinghai.chinatax.gov.cn',
    },
    {
        name:'山西税务',
        url:'http://shanxi.chinatax.gov.cn',
    },
    {
        name:'山东税务',
        url:'http://shandong.chinatax.gov.cn',
    },
    {
        name:'陕西税务',
        url:'http://shanxi.chinatax.gov.cn',
    },
    {
        name:'四川税务',
        url:'http://sichuan.chinatax.gov.cn',
    },
    {
        name:'天津税务',
        url:'http://tianjin.chinatax.gov.cn',
    },
    {
        name:'西藏税务',
        url:'http://xizang.chinatax.gov.cn',
    },
    {
        name:'新疆税务',
        url:'http://xinjiang.chinatax.gov.cn',
    },
    {
        name:'云南税务',
        url:'http://yunnan.chinatax.gov.cn',
    },
    {
        name:'浙江税务',
        url:'http://zhejiang.chinatax.gov.cn',
    }
]

export default  {
    array
}